<template>
    <h3>{{ $t('forget_heading') }}</h3>
    <div class="loginContent">
        <div class="alert alert-success" role="alert" v-if="serverSucess">
              {{ serverSucess }}
         </div>
         <div class="alert alert-danger" role="alert" v-if="serverError">
                                {{ serverError }}
         </div>
        <p>{{ $t('forget_description') }}</p>
         <form v-on:submit.prevent="onForgotPassword()">
            <div class="mb-5 email">
                <label>{{ $t('forget_email') }}</label>
                <input class="form-control" type="text" :placeholder="$t('forget_email')" v-model="email">
            </div>
            <p class="text-center"><button class="btn" type="submit" data-toggle="modal" data-target="#exampleModal">{{ $t('reset_password') }}</button></p>
            <p class="forgot text-center"><router-link to="/login">{{ $t('back_to_login') }}</router-link></p>
        </form>
    </div>
</template>

<script>
import {  mapMutations } from 'vuex'
import axiosInstance from "@/services/AxiosTokenInstance";
import { LOADING_SPINNER_SHOW_MUTATION } from '@/store/storeconstants';
export default {
  title () {
    return `Remote Assistance | ${this.title}`
  },  
    data(){
        return{
            title: 'Forgot Pasword',
            email: '',
            errors: [],
            serverError: '',
            serverSucess:'',
        }
    },
    methods: {
        // ...mapActions('auth',{
        //     client_register: CLIENT_REGISTER,
        // }),
        ...mapMutations({
            showLoading: LOADING_SPINNER_SHOW_MUTATION,
        }),
        
        
        async onForgotPassword(){
            this.serverError = '';
            // let validations = new LoginValidation(this.email)
            // this.errors = validations.checkVelidations();
            // if('email' in this.errors){
            //     return false;
            // }
            // this.showLoading(true);
            
             //this.userId = this.$route.params.userid;
           //console.log("form-submit");
           //this.showLoading(true);
            this.errors = [];            
            let bodyFormData = new FormData();
            bodyFormData.append('email', this.email);
            

            //bodyFormData.append('hotspots', JSON.stringify(this.hotspots));       
            console.log("bodyFormdata",bodyFormData);
            let response = '';

                try{
                        response = await axiosInstance.post(`forgot-password`, bodyFormData);
                        // if(response.status == 200){
                        //     return response.data.data;                
                        // }
                        if(response.status == 200){ 
                            console.log("hello log",response.status);
                                //this.$router.push({path : '/login'})  
                                this.serverSucess = 'The link has been send to your email, Please Check Your Email';
                                    this.showLoading(false);
                                    setTimeout(function(){ location.reload(); }, 4000);  
                                //setTimeout(function(){ location.reload(); }, 3000); 
                                //this.$router.push({path : '/congrat/'+this.userId})       
                                //return 'The file uploaded successfully successfully!!';
                                 
                            } else{
                                //this.$router.push({path : '/client-register/'+this.userId})  
                                this.serverError = 'Email does not exist';
                                    this.showLoading(false); 
                                setTimeout(function(){ location.reload(); }, 3000);       
                                return 'The file uploaded successfully successfully!!';

                            }     
                    }catch(error){
                        throw error.response.status;
                    }
                

                
        }
        
        
    }
        
    
}
</script>